import React from 'react'
import './Footer.scss'
import Slot from '../widgets/Slot';

/**
 * This component represents the footer in the store
 */
const Footer = () => {
  return (
    
    <div className="footer">
      <div className="divider" />
      <div className="footer-txt">© 2024 American Chemical Society. All Rights Reserved.</div>
      {/* <div className="footer-txt">The following purchase terms apply for articles copyrighted by the American Chemical Society (such articles indicate “Copyright 2024 American Chemical Society.”): Purchasers may distribute this material. Please note that articles copyrighted by the ACS cannot be posted on the Internet, including but not limited to a website or listserv. Copies of ACS articles may not be scanned electronically and made available or kept on shared electronic resources for the use of others, including colleagues at the same organization. If additional copies are needed in the form of photocopies, you must report your photocopying to the Copyright Clearance Center at 978-750-8400 or to your Reproduction Rights Organization. Anything that identifies the ACS may not be removed from the material, including but not limited to the ACS logo, ACS name, and publication title. Additionally, the following uses of ACS copyrighted content are also strictly prohibited: · Use by document delivery services and for any fulfillment of document delivery. · Indexing and storage on shared electronic resources for retrieval, distribution, or data mining. Customer agrees to the above terms via its purchase of the ACS material.</div> */}
      <div className="footer-text">
      <a className = "footer-link" href="https://pubstore.acs.org/en-US/terms/">Terms and Conditions</a>
      </div>
      
      
    </div>
  )
}

export default Footer
