import React, { useState, useEffect } from "react";
import urlGenerator from "$ustoreinternal/services/urlGenerator";
import { useNavigate } from "react-router-dom";

import { UStoreProvider } from "@ustore/core";
import Layout from "../components/layout";
import { productIDs, envs } from "../components/consts";
import SearchResults from "../components/Search/SearchResults";
import { Table } from "reactstrap";
import "./Articles.scss";
import "bootstrap/dist/css/bootstrap.min.css";

const Articles = (props) => {
  const [articles, setArticles] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [productID, setProductID] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    getProductId(window.location.host);
  });

  const getProductId = (url) => {
    if (url.includes(envs.DEV)) {
      setProductID(productIDs.DEV);
    } else if (url.includes(envs.STG)) {
      setProductID(productIDs.STG);
    } else if (url.includes(envs.PRODTest) || url.includes(envs.PROD)) {
      setProductID(productIDs.PROD);
    } else {
      setProductID(productIDs.DEV);
    }
  };

  const show = (manID, price, articleName) => {
    const defaultItems = {
      Article: manID,
      Pagecount: price,
      ArticleName: articleName,
    };
    UStoreProvider.state.customState.set("currentItems", defaultItems);

    navigate(
      urlGenerator.get({
        page: "products",
        id: productID,
        name: "Article-Report",
      })
    );
  };

  const setInput = (e) => {
    setInputValue(e.target.value);
  };

  const search = () => {
    if (inputValue.length > 3) {
      fetch(
        // "https://acsdevapi.cierant.com/api/SKU/search/" +
        "https://xmpiedirector.cierant.com/acsapi/api/sku/search/" +
          encodeURIComponent(inputValue)
      )
        .then((response, data) => response.json())
        .then((data) => {
          if (data.length > 0) {
            setArticles(data);
          } else {
            setArticles([]);
          }
        })
        .catch(function (err) {
          // There was an error
          console.warn("Something went wrong.", err);
        });
    }
  };

  return (
    <Layout {...props} className="Articles">
      <div className="container-fluid">
        <div class="wrap" id="section1">
          <div class="row">
            <div className="Article-Title">Articles</div>
            <div className="body-text">
              Article Reprints* are produced on-demand by our production
              partner, Cierant Corporation, and will be billed to you by
              Cierant. All orders will be processed at the time that the article
              is published in an issue. Please allow 10-15 business days for
              shipments within the continental United States and an additional 5
              business days for shipments outside the continental United States.
            </div>

            <div className="body-text">
              To search for an article, please enter the article DOI or
              manuscript ID listed on the article in the box below.
            </div>
            <div className="body-text">
              It may take a few seconds to load your article. To place an order,
              press the Manuscript ID link.
            </div>
          </div>
          <div class="search">
            <input
              class="form-control"
              id="search"
              onChange={setInput}
              onKeyUp={() => search(inputValue)}
              placeholder="Please Enter your manuscript ID or DOI to search"
              type="text"
              value={inputValue}
            />
          </div>
          {articles.length === 0 && inputValue !== "" ? (
            <div>
              <label>
                There are currently no Search Results. Please update your search
                Criteria
              </label>
            </div>
          ) : null}
          {articles.length > 0 ? (
            <div>
              <Table striped>
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">ManuscriptID</th>
                    <th scope="col">Article Title</th>
                    <th scope="col">Page Count</th>
                  </tr>
                </thead>
                <tbody id="tbody">
                  <SearchResults articles={articles} onClick={show} />
                </tbody>
              </Table>
            </div>
          ) : null}
          <div class="row">
            <div className="note-text">
              NOTE:{" "}
              <i>
                Author Use Reprints are made available at special pricing to
                authors listed on the original publication beginning January
                2024. This special pricing can only be obtained via the
                invitation email sent at the time of issue publication. Should
                you need assistance in retrieving a previous email invitation,
                please contact ACS Sales Support at{" "}
                <a href="mailto:acs_pub_assist@acs.org">
                  acs_pub_assist@acs.org
                </a>
                .
              </i>
            </div>
            <div className="note-footnote">
              * <i>Minimum purchase of 250 copies is required.</i>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
//Need to clear the state before setting new values

//Need to clear the state before setting new values
if (UStoreProvider && UStoreProvider.state.customState.currentItems !== null) {
  const defaultItems = [
    { id: 1, Name: "Article", value: "" },
    { id: 2, Name: "Pagecount", value: "" },
    { id: 3, Name: "ArticleName", value: "" },
  ];
  UStoreProvider.state.customState.set("currentItems", defaultItems);
}
const currentItems = [
  { id: 1, Name: "Article", value: "TestID" },
  { id: 2, Name: "Pagecount", value: "14" },
  { id: 3, Name: "ArticleName", value: "Hey I have a cool article Name!" },
];
UStoreProvider.state.customState.set("currentItems", currentItems);

export default Articles;
