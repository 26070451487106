import React, { useState } from "react";
import { UStoreProvider } from "@ustore/core";
import { observer } from "mobx-react-lite";
import { t } from "$themelocalization";
import LoadingDots from "$core-components/LoadingDots";
import CartCheckbox from "../CartList/CartCheckbox";
import CartErrorModel from "../model/CartErrorModel";
import DisplayPrice from "./DisplayPrice";
import CartItemActions from "./CartItemActions";
import ProductName from "./ProductName";
import ProductProperties from "./ProductProperties";
import CartItemThumbnail from "./CartItemThumbnail";
import CartItemAlert from "./CartItemAlert";
import InfoMessage from "./InfoMessage";
import "./index.scss";
import { Nickname } from "./Nickname";

const Price = observer(({ currencyState, price, hasPricing }) =>
  price ? (
    <DisplayPrice
      price={price}
      hasPricing={hasPricing}
      isHighlighted
      currencyState={currencyState}
    />
  ) : (
    <LoadingDots className="loading-dots" />
  )
);

const CartItem = ({ currencyState, item, showSelection = true }) => {
  const {
    orderItemId,
    checked,
    quantity,
    thumbnailUrl,
    quantityPerRecipient,
    numRecipients,
    product,
    errors,
    warnings,
    price,
    _listModel,
    _cartModel,
    properties,
  } = item;
  const [alertMessage, setAlertMessage] = useState("");

  const getItemQuantity = () => {
    const totalQuantity =
      numRecipients && quantityPerRecipient
        ? numRecipients * quantityPerRecipient
        : quantity;
    if (product.unit.packSingular) {
      return quantity > 1
        ? `${totalQuantity} ${product.unit.packPlural}`
        : `${totalQuantity} ${product.unit.packSingular}`;
    } else {
      return quantity > 1
        ? `${totalQuantity} ${product.unit.plural}`
        : `${totalQuantity} ${product.unit.singular}`;
    }
  };

  //const getItemData = async (manuscriptID) => {};

  const onEdit = async () => {
    if (properties.length > 0) {
      const id = properties.find((prop) => prop.name === "Manuscript ID");
      await fetch(
        // "https://acsdevapi.cierant.com/api/SKU/search/" +
        "https://xmpiedirector.cierant.com/acsapi/api/sku/search/" +
          encodeURIComponent(id.value)
      )
        .then((response) => response.json())
        .then((response) => {
          const defaultItems = {
            Article: response[0].manUID,
            Pagecount: response[0].pageCount,
            ArticleName: response[0].articleName,
          };
          UStoreProvider.state.customState.set("currentItems", defaultItems);
        })
        .catch(function (err) {
          // There was an error
          console.warn("Something went wrong.", err);
        });
    }

    item.edit();
  };

  const onMoveItemToWishlist = () => {
    item.moveItemToWishlist();
  };
  const onMoveItemToCart = () => {
    item.moveItemToCart();
  };
  const onDelete = () => {
    setAlertMessage(t("Cart.CartItemDeleted"));
    item.delete();
  };
  const onDuplicateItem = () => {
    //TODO: implement
  };
  const onAssignToList = ({ listType, value }) => {
    //TODO: implement
  };

  const handleTransitionEnd = (e) => {
    if (
      e.target.classList.contains("cart-ng-confirmation-dialog-backdrop") &&
      e.target.classList.contains("fade") &&
      !e.target.classList.contains("show")
    ) {
      onDelete();
    }
  };

  const editDisabled =
    item == null ||
    errors.some(
      ({ errorType }) =>
        errorType === CartErrorModel.CART_ERROR_TYPES.ProductNotAvailable
    );
  const itemInListMode = !_listModel.isUnassigned && _cartModel.isListsMode;

  return (
    <div
      className={`item-box-container ${
        _listModel.isOrderEdit ? "item-order-edit" : ""
      } ${alertMessage ? "close-item" : ""}`}
      onTransitionEnd={handleTransitionEnd}
    >
      <CartItemAlert
        message={alertMessage}
        className={alertMessage ? "move-right" : ""}
      />
      <div
        className={`item-box ${alertMessage ? "move-right" : ""} ${
          showSelection ? "has-selection" : ""
        } `}
      >
        <div className="mobile-title-container">
          <div className="mobile-product-name">
            {showSelection && (
              <div className="cart-item-checkbox-cell">
                <CartCheckbox
                  id={orderItemId}
                  name={orderItemId}
                  className="item-checkbox"
                  checked={checked}
                  onSelect={() => item.toggleSelect()}
                />
              </div>
            )}
            <ProductName item={item} />
          </div>
          <div className="mobile-nickname">
            {item.nickname && (
              <Nickname
                nickname={item.nickname}
                setNickname={(newNickname) => item.updateNickname(newNickname)}
              />
            )}
          </div>
        </div>
        {showSelection && (
          <div className="cart-item-checkbox-cell">
            <CartCheckbox
              id={orderItemId}
              name={orderItemId}
              className="item-checkbox"
              checked={checked}
              onSelect={() => item.toggleSelect()}
            />
          </div>
        )}
        <CartItemThumbnail
          src={thumbnailUrl}
          onThumbnailClick={() => item.edit()}
          orderItemId={orderItemId}
          editDisabled={editDisabled}
        />
        <div className="item-description">
          <ProductName item={item} />
          <Price
            price={price}
            hasPricing={product.hasPricing}
            currencyState={currencyState}
          />
          <ProductProperties product={product} item={item} />
          <div className="total">
            {t("Cart.Item.Total")}: {getItemQuantity()}
          </div>
        </div>
        <div className="item-actions">
          <Price
            price={price}
            currencyState={currencyState}
            hasPricing={product.hasPricing}
          />
          <div className="total">
            {t("Cart.Item.Total")}: {getItemQuantity()}
          </div>
          <CartItemActions
            {...{
              editDisabled,
              item,
              setAlertMessage,
              onEdit,
              onMoveItemToWishlist,
              onMoveItemToCart,
              onDelete,
              onDuplicateItem,
              onAssignToList,
            }}
          />
        </div>
        {errors?.length || warnings?.length ? (
          <div
            className={`cart-item-warning-error-messages ${
              itemInListMode ? "warning-in-list-mode" : ""
            }`}
          >
            {errors?.length ? (
              <InfoMessage type="error" messages={errors} />
            ) : null}
            {warnings?.length ? (
              <InfoMessage type="warning" messages={warnings} />
            ) : null}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default observer(CartItem);
