/**
 * This is the Homepage
 * URL : http://<store-domain>/{language-code}/home/
 *
 * @param {object} state - the state of the store
 */
import React, { Component } from 'react'
import { throttle } from 'throttle-debounce'

import { UStoreProvider } from '@ustore/core'
import { t } from '$themelocalization'

import Layout from '../components/layout'
import CategoryItem from '../components/category/CategoryItem'
import ProductItem from '../components/products/ProductItem'
import PromotionItem from '../components/products/PromotionItem'
import Slider from '$core-components/Slider'
import Gallery from '$core-components/Gallery'

import { getIsNGProduct } from '../services/utils'
import { decodeStringForURL } from '$ustoreinternal/services/utils'
import { getVariableValue } from '$ustoreinternal/services/cssVariables'
import urlGenerator from '$ustoreinternal/services/urlGenerator'
import './Home.scss'
import theme from '$styles/_theme.scss'
import Slot from '../components/widgets/Slot';

class Home extends Component {
  constructor (props) {
    super(props)

    this.state = {
      isMobile: false,
      promotionItemButtonUrl: ''
    }
  }

  componentDidMount () {
    window.addEventListener('resize', this.onResize.bind(this))
    throttle(250, this.onResize) // Call this function once in 250ms only

    this.onResize()
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.onResize)

    this.clearCustomState()
  }

  clearCustomState () {
    UStoreProvider.state.customState.delete('homeFeaturedProducts')
    UStoreProvider.state.customState.delete('homeFeaturedCategory')
    UStoreProvider.state.customState.delete('currentProduct')
    UStoreProvider.state.customState.delete('currentOrderItem')
    UStoreProvider.state.customState.delete('currentOrderItemId')
    UStoreProvider.state.customState.delete('currentOrderItemPriceModel')
    UStoreProvider.state.customState.delete('lastOrder')
    UStoreProvider.state.customState.delete('currentProductThumbnails')
  }

  onResize () {
    this.setState({ isMobile: document.body.clientWidth < parseInt(theme.md.replace('px', '')) })
  }

  static getDerivedStateFromProps (props, state) {
    if (!(props.state && props.customState)) {
      return null
    }

    const { categories } = props.customState
    // NOTE: this is not supported in SSR
    if (categories && categories.length && !state.promotionItemButtonUrl.length) {
      const { FriendlyID, Name } = categories[0]
      const defaultURL = urlGenerator.get({ page: 'category', id: FriendlyID, name: decodeStringForURL(Name) })
      return { promotionItemButtonUrl: getVariableValue('--homepage-carousel-slide-1-button-url', defaultURL, false, true) }
    }
    return null
  }

  render () {
    if (!this.props.state) {
      return null
    }

    const { customState: { categories, homeFeaturedProducts, homeFeaturedCategory } } = this.props

    const promotionItemImageUrl = getVariableValue('--homepage-carousel-slide-1-image', require(`$assets/images/banner_image.png`), true)
    const promotionItemTitle = getVariableValue('--homepage-carousel-slide-1-main-text', t('PromotionItem.Title'))
    const promotionItemSubtitle = getVariableValue('--homepage-carousel-slide-1-sub-text', t('PromotionItem.Subtitle'))
    const promotionItemButtonText = getVariableValue('--homepage-carousel-slide-1-button-text', t('PromotionItem.Button_Text'))

    return (
      <Layout {...this.props} className="home">
        <Slot name="homepage_top" />
        <div className="promotion-wrapper">
          <Slider>
            <PromotionItem
              imageUrl={promotionItemImageUrl}
              title={promotionItemTitle}
              subTitle={promotionItemSubtitle}
              buttonText={promotionItemButtonText}
              url={this.state.promotionItemButtonUrl}
            />
          </Slider>
        </div>

        <div className="middle-section">

          

        {/* <div className="divider" /> */}

 

          <div className = "Section-Right-Image">

          <div className = "Left-Side-Text">
            <div className = "Section-Header">Complete Journal Issues</div>
            <div className = "Section-Text">Journals available for ordering are listed in the Journals menu section. The search box will allow you to browse and search for specific Journal issues.  </div>
            <div className = "Section-Button"><a className = "maxbutton" href="https://xmpiedirector.cierant.com/ustorethemes/ACSReprints/27/en-US/category/758/Journals/">Journals</a>
              

            </div>
            </div>

            <div className = "Right-Side-Image">
            <img src="https://xmpiedirector.cierant.com/ustore/images/ACS-Img/jacsat-145-1.jpg" alt="react logo" className="Section-Image"/>
            </div>
          </div>

          

          <div className = "Section-Left-Image">
          <div className = "Left-Side-Image">
          <img src="https://xmpiedirector.cierant.com/ustore/images/ACS-Img/Articles-Collage.jpg" alt="react logo" className="Section-Image"/>
            </div>
          <div className = "Right-Side-Text">
            <div className = "Section-Header">Individual Articles</div>
            <div className = "Section-Text">Order individual articles from ACS Journals. Individual article reprints are now available for commercial purchase in the Articles menu section. The search box will allow you to input a specific DOI for purchase. In addition to pricing for commercial use, ACS Authors may take advantage of special pricing made available at the time of issue publication. If you are unable to locate your invitation email, please send an email to acs_pubs_assist@acs.org.</div>
            <div className = "Section-Button"><a className = "maxbutton" href="https://xmpiedirector.cierant.com/ustorethemes/ACSReprints/27/en-US/Articles/">Articles</a></div>
            </div>
          </div>

        {/* <div className="welcomeContent">
            <div className="welcome-h2">WELCOME</div>
            <p>Order copies of ACS Journals. You can access volumes that have been released since January of 2010, as well as individual article reprints from these journals. </p>
            <div className="welcome-h3">Complete Journal Issues </div>
            <p>Journals available for ordering are listed in the Journals menu section. The search box will allow you to browse and search for specific Journal issues. </p>
            <div className="welcome-h3">Individual Articles</div>
            <p>Order individual articles from ACS Journals. Individual article reprints are now available for commercial purchase in the Articles menu section. The search box will allow you to input a specific DOI for purchase. In addition to pricing for commercial use, ACS Authors may take advantage of special pricing made available at the time of issue publication. If you are unable to locate your invitation email, please send an email to acs_pubs_assist@acs.org.</p>
          </div>  */}

        <div className="divider" />

        

        </div>

        <Slot name="homepage_bottom" />

      </Layout>
    )
  }
}

Home.getInitialProps = async function (ctx) {
  const maxInPage = 200
  const { Count } = await UStoreProvider.api.categories.getTopCategories(1, maxInPage)
  if (Count === 0) {
    return { homeFeaturedProducts: null, homeFeaturedCategory: null }
  }

  const page = Math.ceil(Count / maxInPage)
  const { Categories } = await UStoreProvider.api.categories.getTopCategories(page, maxInPage)

  if (Categories.length === 0) {
    return { homeFeaturedProducts: null, homeFeaturedCategory: null }
  }

  const homeFeaturedCategory = Categories[Categories.length - 1]
  const { Products: homeFeaturedProducts } = await UStoreProvider.api.products.getProducts(homeFeaturedCategory.ID, 1)

  return { homeFeaturedProducts, homeFeaturedCategory }
}

export default Home
