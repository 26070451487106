import React, { useEffect } from 'react'
import moment from 'moment'
import { observer } from 'mobx-react-lite'
import Icon from '$core-components/Icon'
import { t } from '$themelocalization'
import {getLocaleDateString, getLocaleTimeString} from '$ustoreinternal/services/locale'
import themeContext from '$ustoreinternal/services/themeContext'
import './CartSummaryExportSuccess.scss'


const formatDate = (dateStr) => {
  const locale = themeContext.get('languageCode')
  return dateStr ? moment(dateStr).format(`${getLocaleDateString(locale)} ${getLocaleTimeString(locale)}`): null
}

const CartSummaryExportSuccess = observer(({list}) => {
  const exportSuccess = list?.exportSuccess
  const exportDate = formatDate(exportSuccess?.ExportDate)
  const modifiedDate = formatDate(list.modificationDate)

  useEffect(() => {
    setTimeout(() => {
      list?.clearExportSuccessMessage()
    }, 3000)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list?.exportSuccessMessage])

  return (
    <>
      <div className="cart-summary-success-export-info">
        {list.isModifiedAfterExport && <div className="cart-summary-success-export-modified-warn">
          {t('Cart.Summary.ModificationWarning', {date: modifiedDate})}</div>}
        {exportDate && <div>{t('Cart.Summary.ExportedOn', {date: exportDate})}</div>}
        <a href={exportSuccess?.ExternalUrl} target="_blank" rel="noopener noreferrer">{t('Cart.ListBar.ViewExported')}</a>
      </div>
      {list?.exportSuccessMessage && <div className="cart-summary-success-export">
        {t('Cart.Summary.SuccessExport')}
        <Icon name="checkmark_green.svg" width="16px" height="16px" className="success-icon"/>
      </div>}
    </>
  )
})

export default CartSummaryExportSuccess
