import providerState from './providerState'
import { LOAD_CURRENT_STORE, LOAD_CURRENT_USER, LOAD_CULTURES, LOAD_CURRENCIES, LOAD_CART_ITEMS_COUNT } from '../redux/actionsTypes'
import { getCurrentStore, getCurrentUser, getCultures, getCurrencies, getCartItemsCount } from '../api/store'
import Orders from './orders';
/**
 * Represent the store logic and API in the system
 */
class Store {
  /**
   * Set **state.currentStore** with the data of the store, that is associated with the security token and culture
   * in the request headers.
   * @returns {StoreModel} - the store data
   */
  async loadCurrentStore () {
    const currentStore = await getCurrentStore()
    providerState.dispatch({ type: LOAD_CURRENT_STORE, data: currentStore })
    return currentStore
  }

  /**
   * Set **state.currentUser** with the user, that is logged-in to the store
   * @returns {UserModel} - the user data
   */
  async loadCurrentUser () {
    const currentUser = await getCurrentUser()
    providerState.dispatch({ type: LOAD_CURRENT_USER, data: currentUser })
    return currentUser
  }

  /**
   * Set **state.cultures** with the list of the cultures, that are set for the store.
   * @returns {CultureModel[]} - list of the cultures
   */
  async loadStoreCultures () {
    const cultures = await getCultures()
    providerState.dispatch({ type: LOAD_CULTURES, data: cultures })
    return cultures
  }

  /**
   * Set **state.currencies** with the list of the currencies, that are set for the store.
   * @returns {CurrencyModel[]} - list of the currencies
   */
  async loadStoreCurrencies () {
    const currencies = await getCurrencies()
    providerState.dispatch({ type: LOAD_CURRENCIES, data: currencies })
    return currencies
  }

  async loadCartItemsCount () {
    const cartItemsCount = await Orders.loadCartItemsCount()
    return cartItemsCount
  }
}

export default new Store()
