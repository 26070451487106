import http from './http'
import config from '../services/config'

export const getCurrentStore = async () => {
  return await http.get(`${config.getValue('apiUrl')}/v1/store`)
}

export const getCurrentUser = async () => {
  return await http.get(`${config.getValue('apiUrl')}/v1/store/login/user`)
}

export const getCultures = async () => {
  return await http.get(`${config.getValue('apiUrl')}/v1/store/cultures`, null)
  // Use this for mock data from cultures.json
  // return await http.get(`${config.getValue('baseUrl')}/config/stores/store-a/cultures.json`)
}

export const getCurrencies = async () => {
  return await http.get(`${config.getValue('apiUrl')}/v1/store/currencies`)
  // Use this for mock data from currencies.json
  // return await http.get(`${config.getValue('baseUrl')}/config/stores/store-a/currencies.json`)
}

export const getCartItemsCount = async () => {
  return await http.get(`${config.getValue('apiUrl')}/v1/store/login/user/orders/cart/items/count`)
}

export const renewToken = async () => {
  return await http.post(`${config.getValue('apiUrl')}/v1/store/login/renew`)
}

export const loginBySSOToken = async (Token) => {
  return await http.post(`/uStoreRestApi/v1/store/sso/login`, { Token })
}
