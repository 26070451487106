export const productIDs = {
  DEV: 13520,
  STG: 6564,
  PROD: 7569,
};

export const envs = {
  DEV: "xmpiedev",
  STG: "xmpiestaging",
  PRODTest: "xmpiedirector",
  PROD: "pubstore.acs",
};
