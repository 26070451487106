import React,{useState} from 'react'
import { Input, Modal, ModalBody } from 'reactstrap'
import { t } from '$themelocalization'
import Icon from '$core-components/Icon'
import Button from '$core-components/Button'
import './EditListNameModal.scss'
const EditListNameModal = ({onClose, onSave, value}) => {
  const [newValue, setNewValue] = useState(value);

  return (
    <Modal isOpen={true} modalClassName="edit-list-name-dialog">
      <div className="close-modal">
        <p className="title">{t('Cart.Dialog.EditListName.Title')}</p>
        <button className="close-button" onClick={onClose}>
          <Icon name="close_black.svg" width="14px" height="14px"/>
        </button>
      </div>
      <ModalBody>
        <p className="label">{t('Cart.Dialog.EditListName.ListNameTitle')}</p>
        <Input type="text" value={newValue} onChange={(e) => setNewValue(e.target.value)}/>
        <div className="action-buttons">
          <Button
            text={t('Cart.Dialog.EditListName.Cancel')}
            className="button button-secondary"
            onClick={onClose}
          />
          <Button
            text={t('Cart.Dialog.EditListName.Save')}
            className="button button-primary"
            onClick={onSave}
          />
        </div>
      </ModalBody>
    </Modal>
  )
}

export default EditListNameModal
