import React from 'react'
import { Modal, ModalBody } from 'reactstrap'
import { t } from '$themelocalization'
import Icon from '$core-components/Icon'
import Button from '$core-components/Button'

import './ConfirmListDuplicationModal.scss'

const ConfirmListDuplicationModal = ({ onClose, list, onDuplicate }) => {

  //TODO: replace with computed value
  const count = 9999

  return (
    <Modal isOpen={true}  modalClassName="confirm-list-duplication-modal">
      <div className="close-modal">
        <button className="close-button" onClick={onClose}>
          <Icon name="close_black.svg" width="14px" height="14px"/>
        </button>
      </div>
      <ModalBody>
        <p className="label">{t('Cart.Dialog.DuplicateConfirmation.Label1')}</p>
        <p className="label">{t('Cart.Dialog.DuplicateConfirmation.Label2')}</p>
        <div className="action-buttons">
          <Button
            text={t('Cart.Dialog.DuplicateConfirmation.Cancel')}
            className="button button-secondary"
            onClick={onClose}
          />
          <Button
            text={t('Cart.Dialog.DuplicateConfirmation.Duplicate',{ count })}
            className="button button-primary"
            onClick={() => onDuplicate(list)}
          />
        </div>
      </ModalBody>
    </Modal>
  )
}

export default ConfirmListDuplicationModal
