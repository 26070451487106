import React, { useRef } from 'react'
import { NavHashLink } from 'react-router-hash-link'
import { observer } from 'mobx-react-lite'
import { t } from '$themelocalization'
import { Icon } from '$core-components/Icon'
import List from './List'
import CartListModel from '../model/CartListModel'
import Slot from '../../widgets/Slot'
import './List.scss'
import themeVariables from '$styles/_theme.scss'


const CartList = ({ model, currencyState }) => {
    const navHashLinkRef = useRef()
    const headerHeightDesktop = getComputedStyle(document.documentElement).getPropertyValue('--header-height-desktop');


    const getHeaderOffset = () => {
        return window.innerWidth < parseInt(themeVariables.lg) && headerHeightDesktop ? 0 : parseInt(headerHeightDesktop)
    }
    const scrollWidthOffset = (el) => {
        window.scrollTo({top: el.getBoundingClientRect().top + window.scrollY - getHeaderOffset(), behavior: 'smooth'});
    }

    if (!model) return null

    return (
    <div className={`list-container ${model.isListsMode ? 'cart-lists-mode' : ''}`} id="cart-list">
      <div className="cart-header">
        <div className="list-title">
          {t('Cart.ShoppingCartTitle')} {model.affectPricingItems.length && model.isWishListMode ? `(${model.affectPricingItems.length})` : null}
        </div>
        {
          model.lists.some((list) => list.id === CartListModel.CART_LIST_TYPES.WISH_LIST) &&
          (
              <NavHashLink ref={navHashLinkRef} to={`#wish-list-${CartListModel.CART_LIST_TYPES.WISH_LIST}`} scroll={el => scrollWidthOffset(el)} >
              <Icon name="go_to_wish_list.svg" width="20px" height="20px" title={t('Cart.GoToWishList')}/>
            </NavHashLink>
          )
        }
      </div>
      <Slot name="cart_under_title" />
      {
        model.shoppingCartText &&
          <div className="list-text" dangerouslySetInnerHTML={{ __html: model.shoppingCartText }}/>
      }
      {
        model.affectPricingItems.length === 0 && model.isWishListMode &&
        <div className="list-empty">
          <div className="list-empty-title">{t('Cart.Wishlist.EmptyCartListText')}</div>
          <div className="list-empty-divider"/>
        </div>
      }
      {
        model.affectPricingLists.map((list) => (
          <List
            key={`list-${list.originalOrderFriendlyId ?? list.id}`}
            model={model}
            list={list}
            currencyState={currencyState}
          />
        ))
      }
    </div>
  )
}

export default observer(CartList)
