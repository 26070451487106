import {
  action,
  computed,
  makeObservable,
  observable
} from 'mobx'
import moment from 'moment'
import { t } from '$themelocalization'
import { UStoreProvider } from '@ustore/core'
import urlGenerator from '$ustoreinternal/services/urlGenerator'
import CartSummaryModel from './CartSummaryModel'
import CartItemModel from './CartItemModel'
import CartProductModel from './CartProductModel'
import CartListModel from './CartListModel'
import CartItemPriceModel from './CartItemPriceModel'
import CartErrorModel from './CartErrorModel'
import CartProductUnitModel from './CartProductUnitModel'
import CartActionsModel from './CartActionsModel'
import CartOrderApprovalModel from './CartOrderApprovalModel'
import { CART_MODE } from '../../../services/utils'

class CartModel {
  static CART_ACTIONS = {
    INIT: 'init',
    TOGGLE_SELECT_ALL: 'toggleSelectAll',
    TOGGLE_SELECT_ITEM: 'toggleSelectItem',
    EMPTY_LIST: 'emptyList',
    MOVE_ITEM_TO_WISH_LIST: 'moveItemToWishlist',
    MOVE_ITEM_TO_CART: 'moveItemToCart',
    DELETE_ITEM_FROM_CART: 'deleteItemFromCart',
    EDIT_CART_ITEM: 'editCartItem',
    CHANGE_NICKNAME: 'changeNickname',
  }

  static CART_MODELS = {
    CART: 'cart',
    WISH_LIST: 'wishList',
    CART_VALIDATION: 'cartValidation',
    WISH_LIST_VALIDATION: 'wishListValidation',
    PRICING: 'pricing',
    PRICING_NO_API: 'pricingNoApi',
  }

  constructor ({
    cartMode = CART_MODE.Aspx,
    UStoreProvider,
    storeBaseUrl,
    storeLanguageCode,
    storeApiUrl,
    navigate,
  }) {
    this._uStoreProvider = UStoreProvider
    this._cartActionsModel = new CartActionsModel({
      cartModel: this,
    })
    this._storeData = {
      navigate,
      storeBaseUrl,
      storeLanguageCode,
      storeApiUrl,
    }
    this._cartMode = cartMode
    this._openedListId = CartListModel.CART_LIST_TYPES.UNASSIGNED
    this._lists = []
    this.shoppingCartText = ''
    this.checkoutUrl = ''
    this.items = []
    this.summary = new CartSummaryModel({
      cartModel: this,
    })
    this.initiated = false
    this.loading = false
    this.errors = []
    this.listOpening = false

    makeObservable(this, {
      _uStoreProvider: observable,
      _openedListId: observable,
      initiated: observable,
      _lists: observable,
      shoppingCartText: observable,
      checkoutUrl: observable,
      items: observable,
      summary: observable,
      loading: observable,
      errors: observable,
      listOpening: observable,
      initialLoadItemsFromApi: action,
      loadListsItemsFromApi: action,
      loadSingleListItemsFromApi: action,
      loadCartItemsFromApi: action,
      updateListItemsFromApi: action,
      updateLists: action,
      createCartList: action,
      loadPricingFromApi: action,
      loadWishListItemsFromApi: action,
      updateCartItems: action,
      updateCartPricing: action,
      updateCartValidation: action,
      addList: action,
      addCartError: action,
      removeItems: action,
      moveItemToCart: action,
      touchLists:action,
      openList: action,
      deleteList: action,
      revertModifications: action,
      lists: computed,
      selected: computed,
      storeBaseUrl: computed,
      languageCode: computed,
      storeApiUrl: computed,
      noPricing: computed,
      hasEditOrApproval: computed,
      affectPricingItems: computed,
      isListsMode: computed,
      isWishListMode: computed,
      isSingleListMode: computed,
      openedListItems: computed,
      openedList: computed,
      listsItemsCount: computed,
      [CartListModel.CART_LIST_TYPES.DEFAULT]: computed,
      [CartListModel.CART_LIST_TYPES.WISH_LIST]: computed,
      [CartListModel.CART_LIST_TYPES.UNASSIGNED]: computed,
    })
  }

  async init () {
    // Load cart items count - doesn't need to wait for it
    UStoreProvider.state.store.loadCartItemsCount()

    if (this.isWishListMode) {
      // Load cart items
      await this.initialLoadCartModeWishList()

      // Load cart items pricing
      const cartPricingFromApiChecked = await this._uStoreProvider.api.orders.getCartPricingInfo(true, null)
      this.loadPricingFromApi(cartPricingFromApiChecked)
      const cartPricingFromApiUnchecked = await this._uStoreProvider.api.orders.getCartPricingInfo(false, null)
      this.loadPricingFromApi(cartPricingFromApiUnchecked)

      // Load cart items validation
      const cartValidationFromApiChecked = await this._uStoreProvider.api.orders.validateCart(true, null)
      this.loadValidationFromApi(cartValidationFromApiChecked)
      const cartValidationFromApiUnchecked = await this._uStoreProvider.api.orders.validateCart(false, null)
      this.loadValidationFromApi(cartValidationFromApiUnchecked)
    } else if (this.isListsMode) {
      // Load unassigned items
      await this.initialLoadCartModeLists()

      // Load unassigned items pricing
      const cartPricingFromApiChecked = await this._uStoreProvider.api.orders.getCartPricingInfo(null, null)
      this.loadPricingFromApi(cartPricingFromApiChecked)

      // Load unassigned items validation
      const cartValidationFromApiChecked = await this._uStoreProvider.api.orders.validateCart(null, null)
      this.loadValidationFromApi(cartValidationFromApiChecked)
    } else if (this.isSingleListMode) {
      // Load single list items
      await this.initialLoadCartModeSingleList()

      // Load single list items pricing
      const cartPricingFromApiChecked = await this._uStoreProvider.api.orders.getCartPricingInfo(null, null)
      this.loadPricingFromApi(cartPricingFromApiChecked)

      // Load single list items validation
      const cartValidationFromApiChecked = await this._uStoreProvider.api.orders.validateCart(null, null)
      this.loadValidationFromApi(cartValidationFromApiChecked)
    }

    this._lists.forEach(list => {
      list.loading = false
    })
    this.summary.loading = false

    // Load wishlist
    if (this.isWishListMode) {
      await this.initialLoadWishlist()
    }

    this.initiated = true
  }

  async initialLoadCartModeLists () {
    // load unassigned items
    await this.loadListsItemsFromApi()
  }

  async initialLoadCartModeSingleList () {
    // load single list items
    await this.loadSingleListItemsFromApi()
  }

  async initialLoadCartModeWishList () {
    // load cart items
    await this.loadCartItemsFromApi()
  }

  async initialLoadWishlist () {
    // Load wish list items
    await this.loadWishListItemsFromApi()

    // Load wish list validation
    const wishListValidationFromApi = await this._uStoreProvider.api.orders.validateWishList()
    this.loadValidationFromApi(wishListValidationFromApi)

    // Load wish list pricing
    const wishListPricingFromApi = await this._uStoreProvider.api.orders.getWishListPricingInfo()
    this.loadPricingFromApi(wishListPricingFromApi)
  }

  async initialLoadItemsFromApi () {
    if (this._cartMode === CART_MODE.Lists) {
      await this.initialLoadCartModeLists()
    } else if (this._cartMode === CART_MODE.WishList) {
      await this.initialLoadCartModeWishList()
    }
  }

  async loadListsItemsFromApi () {
    const lists = await this._uStoreProvider.api.orders.getLists()
    lists.forEach((list) => this.addList({
            listId: list.ID || CartListModel.CART_LIST_TYPES.UNASSIGNED,
            title: list.Name || t('Cart.UnassignedItemsListTitle'),
            emptyAllText: null,
            originalOrderFriendlyId: list.OriginalOrderFriendlyID,
            itemsCount: list.ItemsCount || 0,
            sortingDate: list?.SortingDate,
            modificationDate: list?.ModificationDate,
            exportedList: list.ExportDate ? {
              ExternalUrl: list.ExternalUrl,
              ExportDate: list.ExportDate
            } : null
          }))
    this.listOpening = true
    const openListId = lists.reduce((acc, list) => {
      return  moment(list.SortingDate).isAfter(moment(acc.sortingDate)) ?
        {id: list.ID || CartListModel.CART_LIST_TYPES.UNASSIGNED, sortingDate: list.SortingDate} : acc
    }, {id: CartListModel.CART_LIST_TYPES.UNASSIGNED, sortingDate: moment({y: 1970, month:1, d:1})}).id
    this.openList(openListId)
  }

  async loadSingleListItemsFromApi () {
    const { Items } = await this._uStoreProvider.api.orders.getCartInfo()
    const list = (await this._uStoreProvider.api.orders.getLists())[0]
    this._openedListId = list.ID
    const singleList = this.addList({
      listId: list.ID,
      title: list.Name,
      emptyAllText: null,
      originalOrderFriendlyId: list.OriginalOrderFriendlyID,
      itemsCount: list.ItemsCount,
      sortingDate: list.SortingDate,
      modificationDate: list.ModificationDate,
      exportedList: list.ExportDate ? {
        ExternalUrl: list.ExternalUrl,
        ExportDate: list.ExportDate
      } : null
    })
    this.items = this.convertItemsToCartItemsObject(Items, [singleList], list.ID)
  }

  async loadCartItemsFromApi () {
    let { Items, Description, CheckoutUrl, OrderApproval } = await this._uStoreProvider.api.orders.getCartInfo()
    this.shoppingCartText = Description
    if (CheckoutUrl) {
      const qs = CheckoutUrl.split('?')[1]
      this.checkoutUrl = `${urlGenerator.get({ page: 'checkout-final' })}?${qs}`
    }
    const orderApproval = OrderApproval
      ? new CartOrderApprovalModel({
        originalOrderFriendlyId: OrderApproval.OriginalOrderFriendlyID,
        originalOrderId: OrderApproval.OriginalOrderID,
      })
      : null
    if (orderApproval) {
      Items = Items.map(item => ({ ...item, OriginalOrderFriendlyID: orderApproval.originalOrderFriendlyId }))
    }
    const editSections = new Set(Items.filter(item => {
      if (orderApproval?.originalOrderFriendlyId) {
        return item.OriginalOrderFriendlyID != null && item.OriginalOrderFriendlyID !== orderApproval?.originalOrderFriendlyId
      }
      return item.OriginalOrderFriendlyID != null
    }).map(item => item.OriginalOrderFriendlyID || orderApproval?.originalOrderFriendlyId))
    const cartList = this.createCartList([...editSections], orderApproval)
    this.items = this.convertItemsToCartItemsObject(Items, cartList, null)
  }

  async updateListItemsFromApi (listId) {
    const listIdOrNull = listId === CartListModel.CART_LIST_TYPES.UNASSIGNED ? null : listId
    const { Items } = await this._uStoreProvider.api.orders.getCartInfo(listIdOrNull)
    const selectedListItems = Items.reduce((acc, item) => ({ ...acc, [item.OrderItemID]: item }), {})
    const itemsNotInList = this.items.filter(item => !selectedListItems[item.orderItemId])
    this.items = [...itemsNotInList, ...this.convertItemsToCartItemsObject(Items, this._lists, listIdOrNull)]
    const { Items: pricingInfo } = await this._uStoreProvider.api.orders.getCartPricingInfo(null, listIdOrNull)
    this.updateCartPricing(pricingInfo, true)
    const validationInfo = await this._uStoreProvider.api.orders.validateCart(null, listIdOrNull)
    this.updateCartValidation(validationInfo)
  }

  async updateLists () {
    const lists = await this._uStoreProvider.api.orders.getLists()
    if (lists.length === 0) {
      this._lists = []
      return
    }
    this._lists.forEach((list) => {
      const updatedList = lists.find((updatedList) => updatedList.ID === list.id)
      if (updatedList) {
        list.updateListFromApi(updatedList)
      }
    })
  }

  createCartList (editSections, orderApproval) {
    const cartList = [
      this.addList({
        listId: CartListModel.CART_LIST_TYPES.DEFAULT,
        title: t('Cart.SelectAllLabel'),
        emptyAllText: t('Cart.EmptyCartButton'),
      })
    ]
    if (orderApproval) {
      cartList.push(this.addList(
        {
          listId: CartListModel.CART_LIST_TYPES.ORDER_APPROVAL,
          title: t('Cart.OrderApprovalSectionTitle', { orderNumber: orderApproval.originalOrderFriendlyId }),
          emptyAllText: t('Cart.OrderApprovalEmptyCartTitle'),
          orderApproval
        }))
    }
    if (editSections) {
      editSections.forEach(originalOrderFriendlyId => {
        cartList.push(this.addList({
          listId: CartListModel.CART_LIST_TYPES.ORDER_EDIT,
          title: t('Cart.EditOrderSectionTitle', { orderNumber: originalOrderFriendlyId }),
          emptyAllText: '',
          originalOrderFriendlyId
        }))
      })
    }
    return cartList
  }

  loadPricingFromApi (pricingItems) {
    pricingItems.Items.forEach(priceItem => {
      const cartItem = this.items.find(cartItem => cartItem.orderItemId === priceItem.OrderItemID)
      if (cartItem) {
        cartItem.price = new CartItemPriceModel({
          itemModel: cartItem,
          isChanged: priceItem.IsChanged,
          isValid: priceItem.IsValid,
          orderItemId: priceItem.OrderItemID,
          subtotal: priceItem.Price,
          tax: priceItem.Tax,
        })
      }
    })
  }

  loadValidationFromApi (validation) {
    validation.forEach(validationItem => {
      const item = this.items.find(item => item.orderItemId === validationItem.OrderItemID)
      // In case orderItemId in error response is null, the error is a cart-level error
      if (item instanceof CartItemModel) {
        item.addValidation(validationItem)
      } else if (item === undefined) {
        this.addCartError(validationItem)
      }
    })
  }

  convertItemToCartItemObject (item, list, displayOrder = 0) {
    return new CartItemModel({
      cartModel: this,
      listModel: list,
      displayOrder,
      orderItemId: item.OrderItemID,
      originalOrderFriendlyId: item.OriginalOrderFriendlyID,
      listId: item.ListID ?? list.id,
      checked: item.Checked,
      quantity: item.Quantity,
      thumbnailUrl: item.Thumbnail?.Url ? `${this._storeData.storeApiUrl}/${item.Thumbnail.Url}` : null,
      editUrl: urlGenerator.get({ page: 'products' }).replace('/products', item.EditUrl),
      nickname: item.Nickname,
      quantityPerRecipient: item.QuantityPerRecipient,
      numRecipients: item.NumRecipients,
      product: new CartProductModel({
        productId: item.ProductID,
        name: item.Product.Name,
        catalogNumber: item.Product.CatalogNumber,
        unit: new CartProductUnitModel({
          quantity: item.Product.Unit.ItemQuantity,
          singular: item.Product.Unit.ItemType.Name,
          plural: item.Product.Unit.ItemType.PluralName,
          packSingular: item.Product.Unit.PackType?.Name,
          packPlural: item.Product.Unit.PackType?.PluralName,
        }),
        hasPricing: item.Product.HasPricing,
      }),
      properties: item.Properties.map(property => ({
        name: property.Name,
        value: property.Value,
      }))
    })
  }

  convertItemsToCartItemsObject (items, lists, listId = null) {
    return items.map((item, index) => {
      // Set default list or if listId provided - will be overridden later if needed
      const defaultList = this.isWishListMode ? CartListModel.CART_LIST_TYPES.DEFAULT : CartListModel.CART_LIST_TYPES.UNASSIGNED
      let list = listId ? lists.find(list => list.id === listId) : lists.find(list => list.id === defaultList)
      if (item.OriginalOrderFriendlyID) {
        // If there is an originalOrderFriendlyId, the item is in an edit/approval section
        list = lists.find(list => list.originalOrderFriendlyId === item.OriginalOrderFriendlyID)
      }

      return this.convertItemToCartItemObject(item, list, index)
    })
  }

  convertItemsToWishlistItemsObject (items, list) {
    return items.map(item => this.convertItemToCartItemObject(item, list))
  }

  async loadWishListItemsFromApi () {
    const { Items } = await this._uStoreProvider.api.orders.getWishListInfo()
    const wishList = this.addList({
      listId: CartListModel.CART_LIST_TYPES.WISH_LIST,
      title: t('Cart.Wishlist.WishListTitle'),
      emptyAllText: t('Cart.EmptyCartButton'),
      affectPricing: false,
    })
    const wishListItems = this.convertItemsToWishlistItemsObject(Items, wishList)

    this.items.push(...wishListItems)
  }

  /**
   * Wishlist mode - Update cart should be called in the following events:
   * 1. Before checkout - selected only
   * 2. Selecting/unselecting items - selected only
   * 3. Moving and item to/from wish list - selected only
   * 4. Deleting an item/emptying the cart - selected only
   *
   * Lists mode - update cart should be called in the following events:
   * 1. Selecting/unselecting items - unassigned only
   * 2. Moving an item to another list/to a new list
   * 3. Deleting an item
   */
  async updateCart ({ selectedOnly, listId , updateLists  } = {selectedOnly :true, listId : null, updateLists: false}) {
    try {
      if (this.isWishListMode) {
        await this._cartActionsModel.updateCart(selectedOnly)
      } else if (this.isListsMode) {
        await this.updateListItemsFromApi(listId || this.openedList?.id)
        updateLists && await this.updateLists()
      }
    } catch (error) {
      console.error(error)
    }
  }

  updateCartItems (itemsFromApi) {
    this.items = this.items.map((item) => {
      const itemFromApi = itemsFromApi.find(itemFromApi => itemFromApi.OrderItemID === item.orderItemId)
      return itemFromApi ? this.convertItemToCartItemObject(itemFromApi, this[CartListModel.CART_LIST_TYPES.DEFAULT]) : item
    })
  }

  updateCartPricing (priceItems) {
    this.items.forEach(item => {
      const priceItem = priceItems.find(priceItem => priceItem.OrderItemID === item.orderItemId)
      priceItem && item.price.updateFromApi(priceItem)
    })
  }

  updateCartValidation (validations) {
    this.errors = []
    const cartValidations = validations.filter(validation => validation.OrderItemId == null)
    cartValidations.forEach(validation => this.addCartError(validation))

    this.items.forEach(item => {
      const itemValidations = validations.filter(validation => validation.OrderItemID === item.orderItemId)
      itemValidations && item.updateValidations(itemValidations)
    })
  }

  addList ({
    listId, title, emptyAllText, affectPricing = true, originalOrderFriendlyId = null, itemsCount = null,
    exportedList = null, skipLoading = false, orderApproval = null, sortingDate = null, modificationDate = null
  }) {
    const list = new CartListModel({
      cartModel: this,
      id: listId,
      title,
      emptyAllText,
      affectPricing,
      originalOrderFriendlyId,
      itemsCount,
      exportedList,
      skipLoading,
      orderApproval,
      sortingDate,
      modificationDate
    })
    const unassignedListIndex = this._lists.findIndex(list => list.id === CartListModel.CART_LIST_TYPES.UNASSIGNED)
    if (this.isListsMode && unassignedListIndex > -1) {
      this._lists.splice(unassignedListIndex + 1, 0, list)
    } else {
      this._lists.unshift(list)
    }
    return list
  }

  addCartError (error) {
    if (this.errors.some(err => err.errorType === error.Error.Type)) return
    this.errors.push(new CartErrorModel({
      errorLevel: error.Error.Level,
      message: error.Error.Message,
      errorType: error.Error.Type,
    }))
  }

  removeItems (orderItemIds) {
    this.items = this.items.filter(item => !orderItemIds.includes(item.orderItemId))
  }

  moveItemToCart (item) {
    const cartList = this[CartListModel.CART_LIST_TYPES.DEFAULT]
    cartList.items.forEach(cartItem => cartItem.displayOrder++)

    const list =
      item.originalOrderFriendlyId
        ? this._lists.find(list => list.originalOrderFriendlyId === item.originalOrderFriendlyId)
        : cartList
    item.listId = list.id
    item._listModel = list
    item.displayOrder = 0
  }

  moveItemToWishList (item) {
    const wishList = this[CartListModel.CART_LIST_TYPES.WISH_LIST]
    wishList.items.forEach(cartItem => cartItem.displayOrder++)
    item.updateValidations([])
    item.listId = wishList.id
    item._listModel = wishList
    item.displayOrder = 0
  }

  deleteList (listId) {
    this._lists = this._lists.filter(list => list.id !== listId)
  }

  async openList (listId) {
    try {
      this.listOpening = true
      this._openedListId = listId
      if (listId !== null) {
        await this.updateListItemsFromApi(listId)
      }
    } catch (e) {
      console.error(e)
    } finally {
      this.listOpening = false
    }
  }

  get lists () {
    if (!this.isListsMode) {
      return this._lists
    }
    const sorted = [...this._lists].sort((a, b) => {
        if (a.id === CartListModel.CART_LIST_TYPES.UNASSIGNED) {
          return -1
        } else if (b.id === CartListModel.CART_LIST_TYPES.UNASSIGNED) {
          return 1
        }
        return moment(b.sortingDate).toDate().valueOf() - moment(a.sortingDate).toDate().valueOf()
      }
    )

    return sorted
  }

  get selected () {
    return this.items.filter(item => item.checked)
  }

  get storeBaseUrl () {
    return this._storeData.storeBaseUrl
  }

  get languageCode () {
    return this._storeData.storeLanguageCode
  }

  get storeApiUrl () {
    return this._storeData.storeApiUrl
  }

  get noPricing () {
    return this.affectPricingLists.some((list) => list.noPricing)
  }

  get hasEditOrApproval () {
    return this._lists.some(list => list.originalOrderFriendlyId != null)
  }

  get affectPricingItems () {
    return this.items.filter(item => item.affectsPricing)
  }

  get affectPricingLists () {
    return this.lists.filter(list => list?.affectPricing)
  }

  get isListsMode () {
    return this._cartMode === CART_MODE.Lists || localStorage.getItem('features.CartLists') === 'true'
  }

  get isWishListMode () {
    return this._cartMode === CART_MODE.WishList
  }

  get isSingleListMode () {
    return this._cartMode === CART_MODE.SingleList
  }

  get openedListItems () {
    if (this._openedListId === null) return null
    return this.items.filter(item => item.listId === this._openedListId)
  }

  get openedList () {
    return this._lists.find(list => list.id === this._openedListId) ?? null
  }

  get listsItemsCount () {
    return this._lists.reduce((total, list) => total + list.itemsCount, 0)
  }

  get [CartListModel.CART_LIST_TYPES.DEFAULT] () {
    return this._lists.find(list => list.id === CartListModel.CART_LIST_TYPES.DEFAULT) ?? null
  }

  get [CartListModel.CART_LIST_TYPES.WISH_LIST] () {
    return this._lists.find(list => list.id === CartListModel.CART_LIST_TYPES.WISH_LIST) ?? null
  }

  get [CartListModel.CART_LIST_TYPES.UNASSIGNED] () {
    return this._lists.find(list => list.id === CartListModel.CART_LIST_TYPES.UNASSIGNED) ?? null
  }

  async checkout () {
    await this.updateCart()
    if (this.summary.hasErrors) {
      return
    }
    this._storeData.navigate(this.checkoutUrl)
  }

  async revertModifications () {
    await this._uStoreProvider.api.orders.revertModifications()
    this.items = this.items.filter(item => item._listModel.id !== CartListModel.CART_LIST_TYPES.ORDER_APPROVAL)
    this._uStoreProvider.state.store.loadCartItemsCount()
    this._lists = this._lists.filter(list => list.id !== CartListModel.CART_LIST_TYPES.ORDER_APPROVAL)
  }

  async touchLists() {
    this._lists = [...this._lists]
  }
}

export default CartModel
