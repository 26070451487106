import { useEffect, useState } from 'react'
import { UStoreProvider } from '@ustore/core'
import './Cart.scss'
import urlGenerator from '$ustoreinternal/services/urlGenerator'
import { Link } from 'react-router-dom'
import Icon from '$core-components/Icon'
import {getCartMode, CART_MODE} from '../../../services/utils'

/**
 * This component represents the cart icon.
 * When clicking on it - the store redirects to the 'Cart' page
 */
const Cart = ({ connectCartUrl = '' }) => {
  const storeItemsCount = UStoreProvider.state.get().cartItemsCount?.ItemsCount
  const [cartItemsCount, setCartItemsCount] = useState(storeItemsCount)
  const isCartLists = getCartMode(UStoreProvider.state.get().currentStore) === CART_MODE.Lists

  useEffect(() => {
    setCartItemsCount(UStoreProvider.state.get().cartItemsCount?.ItemsCount)
  }, [storeItemsCount])

  const getBadge = () => {
    if (isCartLists && cartItemsCount) {
      return <div className="cart-badge"/>
    }
    if (cartItemsCount) {
      return <div className="cart-badge">{cartItemsCount >= 100 ? '∞' : cartItemsCount}</div>
    }
    return null
  }

  const cartComponent = () => {
    return <div className="cart-icon-container">
      <Icon name="cart.svg" width="23px" height="21px" className="cart-icon" />
      {getBadge()}
    </div>
  }

  return (
    <div className="cart">
      {
        connectCartUrl
          ? <a href={decodeURIComponent(connectCartUrl)}>{cartComponent()}</a>
          : <Link to={urlGenerator.get({ page: 'cart' })}>
            <span>
              {cartComponent()}
            </span>
          </Link>
      }
    </div>
  )
}

export default Cart
