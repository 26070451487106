import React, { useState, useEffect } from "react";
import urlGenerator from "$ustoreinternal/services/urlGenerator";
import { useNavigate } from "react-router-dom";

import { UStoreProvider } from "@ustore/core";
import Layout from "../components/layout";
import "./ContactUs.scss";

const ContactUs = (props) => {
    return (
      <Layout {...props} className="contact-us">
        <div className="container-fluid">
          <h2 className="text-center">Contact Us</h2>
          <div className="row">
            <div className="col-sm-5">
              <p> </p>
              <p><strong>Print Production and Delivery Questions</strong></p>
              <p>Print and Production for Journals and Articles are provided by Cierant. If you have questions about print and production you can email Cierant at the address below or use the form to the right. </p>
              <p><a href="acspubs@cierant.com">acspubs@cierant.com</a></p> 
            </div>
            <div className="col-sm-7">
              <div className="row">
                <div className="col-sm-6 form-group">
                  <input className="form-control" id="name" name="name" placeholder="Name" type="text" required />
                </div>
                <div className="col-sm-6 form-group">
                  <input className="form-control" id="email" name="email" placeholder="Email" type="email" required />
                </div>
              </div>
              <textarea className="form-control" id="comments" name="comments" placeholder="Comment" rows="5"></textarea><br />
              <div className="row">
                <div className="col-sm-12 form-group">
                  <button className="maxbutton" type="submit">Contact Cierant</button>
                </div>
              </div> 
            </div>
          </div>
          <div className="row">
            <div className="col-sm-5">
            <p> </p>
            <p><strong>Publication Questions</strong></p>
              <p>For publication questions, including if you are an author and you need help getting special pricing, please contact ACS Publications at the email below, or use the form to the right.</p>
              <p><a href="mailto:acs_pub_assist@acs.org">acs_pub_assist@acs.org</a></p> 
            </div>
            <div className="col-sm-7">
              <div className="row">
                <div className="col-sm-6 form-group">
                  <input className="form-control" id="name" name="name" placeholder="Name" type="text" required />
                </div>
                <div className="col-sm-6 form-group">
                  <input className="form-control" id="email" name="email" placeholder="Email" type="email" required />
                </div>
              </div>
              <textarea className="form-control" id="comments" name="comments" placeholder="Comment" rows="5"></textarea><br />
              <div className="row">
                <div className="col-sm-12 form-group">
                  <button className="maxbutton" type="submit">Contact ACS Publications</button>
                </div>
              </div> 
            </div>
          </div>
        </div>
      </Layout>
    )
}
  
export default ContactUs
