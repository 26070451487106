import React, { useState, useEffect } from "react";
import urlGenerator from "$ustoreinternal/services/urlGenerator";
import { useNavigate } from "react-router-dom";

import { UStoreProvider } from "@ustore/core";
import Layout from "../components/layout";
import "./Unsubscribe.scss";

const Unsubscribe = (props) => {
    return (
      <Layout {...props} className="Unsubscribe">
        <div className="container-fluid">
          <h2>Thank you, you have been unsubscribed.</h2>
          <p>You have been successfully unsubscribed from our marketing emails. We're sorry to see you go, but we respect your decision. This means you will no longer receive updates, promotions, and valuable content from us.</p>
          <p>If you've unsubscribed in error or wish to resubscribe, you're always welcome to do so by visiting our website or contacting our customer support team.</p>
          <p>We genuinely appreciate your past engagement and interest in our brand. Should you change your mind in the future, we'll be here!</p>
          <p>If you have any feedback or suggestions regarding our emails or products, we would love to hear from you. Your insights are valuable to us and help us improve our offerings.</p>
          <p>Thank you once again, and we wish you all the best.</p>
        </div>
      </Layout>
    )
}
  
export default Unsubscribe
