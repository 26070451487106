import React from "react";

const SearchResults = ({ articles, onClick }) => {
  return (
    <>
      {articles.map((article) => {
        const { articleName, id, manUID, pageCount } = article;
        return (
          <tr key={id}>
            <td>{id}</td>
            <td>
              <div
                onClick={() => onClick(manUID, pageCount, articleName)}
                style={{ color: "blue", cursor: "pointer" }}
              >
                {manUID}
              </div>
            </td>
            <td>{articleName}</td>
            <td>{pageCount}</td>
          </tr>
        );
      })}
    </>
  );
};

export default SearchResults;
