import Home from "./Home";
import Category from "./Category";
import Product from "./Product";
import Products from "./Products";
import OrderFinalStep from "./OrderFinalStep";
import Addresses from "./Addresses";
import Cart from "./Cart";
import CheckoutComplete from "./CheckoutComplete";
import CheckoutFinal from "./CheckoutFinal";
import FileSubmission from "./FileSubmission";
import CheckoutPaymentSubmission from "./CheckoutPaymentSubmission";
import MyRecipientLists from "./MyRecipientLists";
import OrderHistory from "./OrderHistory";
import PersonalInformation from "./PersonalInformation";
import Customization from "./Customization";
import UeditCustomization from "./uEditCustomization";
import SplitShippingAddresses from "./SplitShippingAddresses";
import OrderDetails from "./OrderDetails";
import SplitShippingItems from "./SplitShippingItems";
import Recipient from "./Recipient";
import ProductUnavailable from "./ProductUnavailable";
import Drafts from "./Drafts";
import EmptyPage from "./EmptyPage";
import ErrorPage from "./ErrorPage";
import Search from "./Search";
import OrderApprovalList from "./OrderApprovalList";
import CustomMain from "./CustomMain";
import ClearingUnknownOrder from "./ClearingUnknownOrder";
import ProductDetails from "./ProductDetails";
import ConnectLoggedOut from "./ConnectLoggedOut";
import Articles from "./Articles";
import ContactUs from "./ContactUs";
import Terms from "./Terms";
import Unsubscribe from "./Unsubscribe";

const routes = {
  Home,
  Category,
  Product,
  Products,
  OrderFinalStep,
  Addresses,
  Cart,
  CheckoutComplete,
  CheckoutFinal,
  FileSubmission,
  CheckoutPaymentSubmission,
  MyRecipientLists,
  OrderHistory,
  PersonalInformation,
  Customization,
  UeditCustomization,
  SplitShippingAddresses,
  OrderDetails,
  SplitShippingItems,
  Recipient,
  ProductUnavailable,
  Drafts,
  EmptyPage,
  ErrorPage,
  Search,
  OrderApprovalList,
  CustomMain,
  ClearingUnknownOrder,
  ProductDetails,
  ConnectLoggedOut,
  Articles,
  ContactUs,
  Terms,
  Unsubscribe
  
};

export default routes;
