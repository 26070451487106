import React, { useState, useEffect } from "react";
import urlGenerator from "$ustoreinternal/services/urlGenerator";
import { useNavigate } from "react-router-dom";

import { UStoreProvider } from "@ustore/core";

import Layout from "../components/layout";
import "./Terms.scss";

const Terms = (props) => {
  return (
    <Layout {...props} className="Terms">
      <div className="content">
        <div className="headline">Terms of Use--Reprints</div>
        <div className="bodyCopy">
          <p className="Terms-BodyText">
            American Chemical Society (“ACS”) of 1155 16th Street, NW,
            Washington, D.C., 20036 owns all right, title, and interest in its
            products and services (“ACS Products”). ACS hereby grants the
            Purchaser non-exclusive, non-transferable permission to distribute
            the purchased reprints of the selected journal article from an ACS
            Product, under the terms and conditions of this Agreement (the
            journal article and the reprints, together, the “ACS Article”). By
            completing the purchase transaction, Purchaser agrees to this
            Agreement.
          </p>
          <ol className="Terms-List">
            <li className="Terms-List-Item">
              Except for any author rights afforded the Purchaser if Purchaser
              is an author of the ACS Article, Purchaser hereunder may solely
              distribute the ACS Article to participants as part of a
              presentation at a seminar, conference, or workshop, or other such
              similar activity. Purchasers must inform each recipient that no
              further reproduction, sharing, or distribution (to colleagues,
              repositories, third parties, posting on webpages or listservs,
              etc.) is allowed. For ACS Articles published under open access
              conditions, recipients are subject to the terms and conditions of
              the CC-BY or CC-BY-NC-ND license identified in the ACS Article.
            </li>
            <li className="Terms-List-Item">
              The ACS Article may not be scanned electronically and made
              available or kept on shared electronic resources for the use of
              others, including, but not limited to, colleagues at the same
              organization. If additional copies are needed in the form of
              photocopies, you must report your photocopying to the Copyright
              Clearance Center at 978-750-8400 or to your Reproduction Rights
              Organization.
            </li>
            <li className="Terms-List-Item">
              The reprints or any photocopies permitted must be exact copies of
              the original ACS Article and retain original ACS copyright notices
              and citations. No modifications to the ACS Article may be made.
              Anything that identifies the ACS may not be removed from the
              material, including but not limited to the ACS logo, ACS name, and
              publication title.
            </li>
            <li className="Terms-List-Item">
              The ACS Article cannot be posted on the Internet, including but
              not limited to a website or listserv.
            </li>
            <li className="Terms-List-Item">
              For others who may desire to use the ACS Article, Purchaser may
              solely share the DOI link that points back to the ACS Article on
              the ACS platform.
            </li>
            <li className="Terms-List-Item">
              Use by document delivery services and for any fulfillment of
              document delivery is prohibited.
            </li>
            <li className="Terms-List-Item">
              Indexing and storage on shared electronic resources for retrieval,
              distribution, or data mining is prohibited.
            </li>
            <li className="Terms-List-Item">
              The ACS Article may not be modified or adapted, nor a derivative
              product made from it, without express written permission from ACS
              in each instance.
            </li>
            <li className="Terms-List-Item">
              Purchaser may not indicate endorsement by ACS.
            </li>
            <li className="Terms-List-Item">
              Purchaser must immediately cease use of and destroy any copies in
              its possession (and certify destruction) upon reasonable written
              request of ACS, such as (but not limited to) in instances of
              violation of the terms of use or of a copyright infringement
              claim.
            </li>
            <li className="Terms-List-Item">
              ACS MAKES NO WARRANTY OR REPRESENTATION OF ANY KIND, EXPRESS OR
              IMPLIED, REGARDING THE ACS PRODUCTS INCLUDING THEIR QUALITY,
              ORIGINALITY, SUITABILITY, SEARCHABILITY, OPERATION, PERFORMANCE,
              COMPLIANCE WITH ANY COMPUTATIONAL PROCESS, MERCHANTABILITY OR
              FITNESS FOR A PARTICULAR PURPOSE. ACS SHALL NOT BE LIABLE FOR:
              EXEMPLARY, SPECIAL, INDIRECT, INCIDENTAL, CONSEQUENTIAL OR OTHER
              DAMAGES ARISING OUT OF OR IN CONNECTION WITH THE AGREEMENT GRANTED
              HEREUNDER, THE USE OR INABILITY TO USE ANY ACS PRODUCT, ACS’S
              PERFORMANCE UNDER THIS AGREEMENT, TERMINATION OF THIS AGREEMENT BY
              ACS OR THE LOSS OF DATA, BUSINESS OR GOODWILL EVEN IF ACS IS
              ADVISED OR AWARE OF THE POSSIBILITY OF SUCH DAMAGES. IN NO EVENT
              SHALL THE TOTAL AGGREGATE LIABILITY OF ACS OUT OF ANY BREACH OR
              TERMINATION OF THIS AGREEMENT EXCEED THE TOTAL AMOUNT PAID BY
              GRANTEE FOR ACCESS TO ACS PRODUCTS FOR THE CURRENT YEAR IN WHICH
              SUCH CLAIM, LOSS OR DAMAGE OCCURRED, WHETHER IN CONTRACT, TORT OR
              OTHERWISE, INCLUDING, WITHOUT LIMITATION, DUE TO NEGLIGENCE. The
              foregoing limitations and exclusions of certain damages shall
              apply regardless of the success or effectiveness of other
              remedies. No claim may be made against ACS unless made within one
              (1) year after the event giving rise to the claim.
            </li>
          </ol>
        </div>
      </div>
    </Layout>
  );
};

export default Terms;
